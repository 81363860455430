import i18n from '@/vendors/i18n'

export default {
  name: 'project_start_date',
  model: 'actions_data.project.start_date',
  type: 'input_date',
  settings: {
    icon: 'input_date',
    category: 'project_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_project_start_date')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block.project_start_date_label'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ],
  is_allowed: {
    'procedure.actions': ['project']
  }
}
